import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import Component_Home from "./Component__Home";
import { formatDate } from "./helpers";
import Component__Home__Loader from "./Component__Home__Loader";
import { AnimBloc } from "../../js/anim";

import { motion } from "framer-motion";
import { Link } from "react-router-dom";


function Home() {
  const userstore = useSelector((state) => state.user.user);
  const matchlist = useSelector((state) => state.match.match);
  const pronolist = useSelector((state) => state.prono.prono);

  const [userState, setUserState] = useState(null);
  const [pronoState, setPronoState] = useState(null);
  const [pronoFilterState, setPronoFilterState] = useState(null);
  const [groupedMatches, setGroupedMatches] = useState(null);
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    setUserState(userstore);
  }, [userstore]);

  useEffect(() => {
    if (pronolist && matchlist) {
      let filteredArr = [];
      setPronoState(pronolist);

      matchlist.forEach(item => {
        if (item.is_play === false) {
          filteredArr.push(item);
        }
      });
      setPronoFilterState(filteredArr);
    }
  }, [pronolist, matchlist]);

  useEffect(() => {
    // Cette fonction regroupe les matches par date
    if (pronoFilterState) {
      const grouped = {};
      pronoFilterState.forEach(match => {
        if (!grouped[match.date]) {
          grouped[match.date] = [];
        }
        grouped[match.date].push(match);
      });
      setGroupedMatches(grouped);
      //AnimBloc()
    }
  }, [pronoFilterState]);

  // useEffect(() => {
  //   if(groupedMatches){AnimBloc();}
  // }, [groupedMatches]);

  function toggleLoader(bool){
    setShowLoader(bool)
  }

  return (
    <div className='Home'>
      {showLoader ? <Component__Home__Loader /> : null }

      {userState ? 
        <h1 className="MainH1">Bienvenue {userState.username}</h1>
      : null}
      <p className="Maindescription">Content de te revoir ! De nouveaux matchs sont disponibles pour tes pronos.</p>
      {groupedMatches ? 
        <div className="Home__container">
          {Object.keys(groupedMatches).reverse().map(date => (
            <div className="Home__container__date" key={date}>
              <p className="gsap__AnimScroll">{formatDate(date)}</p>
              <div className="Home__container__blocmaths">
                {groupedMatches[date].sort((a, b) => a.heure.localeCompare(b.heure)).map(match => (
                  <div key={match.id} className="gsap__AnimScroll">
                    <Link to={'/home/details/' + match.id}><Component_Home data={match} toggle={toggleLoader}/></Link>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="Home__end">
            <img src="/media/eyes.svg" alt="terrain" />
            <p>Mince, il n'y a pas d'autres pronos disponibles pour le moment, reviens plus tard. </p>
          </div>
        </div>
      : <div className="loading">Chargement...</div>} 
    </div>
  );
}

export default Home;
