import React, { useEffect } from 'react';
import { useSelector } from "react-redux/es/hooks/useSelector";

function ComponentRank(props) {
    
    const user = useSelector((state) => state.user.user);

    useEffect(() => {
    }, []);

  return (
    <div>
      <div className={`Classement__tableau__item ${props.name == user.username ? "perso" : ""} ${(props.rank % 2 === 0) ? "paire" : "" }`}>
        <div className="Classement__tableau__item__left">
          <span className="FLight Classement__tableau__item__left__rank unit">{props.rank}</span>
          <div  className='Classement__tableau__item__left__profil'>
            <img alt={props.name} src={props.avatar}/>
          </div>
          <span className="Classement__tableau__item__left__name">{props.name}</span>
        </div>
        <div className="Classement__tableau__item__right">
          {props.valueToShow === 'score' ? (
            <span className="Classement__tableau__item__right__score">
              {props.score} <span className="unit">pts</span>
            </span>
          ) : null}
          {props.valueToShow === 'seum' ? (
            <span className="Classement__tableau__item__right__score">
              {/* {props.seum} <span className="unit">coef. /</span>  */}
              {props.scoreseum} <span className="unit">pts</span>
            </span>
          ) : null}
          {props.valueToShow === 'exactprono' ? (
            <span className="Classement__tableau__item__right__score">{props.exactprono}</span>
          ) : null}
          {props.valueToShow === 'bonprono' ? (
            <span className="Classement__tableau__item__right__score">{props.bonprono}</span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ComponentRank;
