import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function ComponentProfil(props) {

    const userconnected = useSelector((state) => state.user.user);
    const userlist = useSelector((state) => state.users.users);

    const [moyStat, setMoyStat] = useState(null);
    const [dimension, setDimension] = useState({
        perso : 100,
        general : 100,
    });

    useEffect(() => {
        if (userconnected && props.ctg) {
            //CALCULER LA MOYENNE DES JOUEURS
            let arr = []
            userlist.forEach(user => {
                arr.push(user[props.ctg])
            });
            let somme = 0;
            for (let i = 0; i < arr.length; i++) {somme += arr[i];}
            let moyenne = (somme / arr.length).toFixed(2);
            setMoyStat(moyenne)  
        }
    }, [props.ctg, userconnected]);

    useEffect(() => {
        //ANIMATION BAR
        const proportion = userconnected[props.ctg] / parseFloat(moyStat);
        if(proportion > 1){
            let finalwidth = (100/proportion).toFixed(2)
            if(finalwidth < 17 || finalwidth == Infinity){
                finalwidth = 17
            }
            console.log(finalwidth);
            setDimension({
                ...dimension,
                general : finalwidth,
            })
        }
         if(proportion < 1){
            let finalwidth = (100*proportion).toFixed(2)
            if(finalwidth < 17 || finalwidth == Infinity){
                finalwidth = 17
            }
            setDimension({
                ...dimension,
                perso : finalwidth,
            })
        }
        else {
            return;
        }
    }, [moyStat]);

    return (
        <div className='Reglage__profil__stats__bloc__item'>
            <p>{props.label} :</p>
            <div className='Reglage__profil__stats__bloc__item__perso' style={{ width: `${dimension.perso}%` }} >
                <span>Moi</span>
                <span>{userconnected && props.ctg ? userconnected[props.ctg] : 'N/A'}</span>
            </div>
            <div className='Reglage__profil__stats__bloc__item__general' style={{ width: `${dimension.general}%`, minWidth: 'fit-content' }} >
                <span>Moy.</span>
                <span>{moyStat}</span>
            </div>
        </div>
    );
}

export default ComponentProfil;
