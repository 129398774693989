//GENERAL
import { Route, Routes, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { supabase } from './js/http-common';
import { ErrorBoundary } from "react-error-boundary";

//COMP
import Component__Navbar from './components/Navbar/Component__Navbar';
import Component__Intro from './components/Intro/Component__Intro';

//PAGE
import Home from './pages/Home/Home';
import Reglage from './pages/Reglage/Reglages';
import Signin from './pages/Register/Signin';
import Signup from './pages/Register/Signup';
import Classement from './pages/Classement/Classement';
import Match from './pages/Matchs/Match';
import Matchdetail from './pages/MatchDetails/MatchDetail';
import Admin from './pages/Admin/Admin';
import Reglement from './pages/Reglement/reglement';
import Fonctionnement from './pages/Fonctionnement/fonctionnement';
import UpdateProfil from './pages/UpdateProfil/updateProfil';
import MatchStats from './pages/MatchStats/MatchStats';

//STYLE 
import './index.scss';
import { AnimatePresence } from 'framer-motion';

//STORE
import { useSelector, useDispatch } from 'react-redux'
import { getAllUsers, getAllMatch, getAllProno } from './store/data';
import { setUsers } from './reducers/usersSlice';
import { setMatch } from './reducers/matchSlice';
import { setProno } from './reducers/pronoSlice';
import { setUser } from "./reducers/userSlice";
//CRON
import { restartCron } from './js/cron';

//AUTRE
import {useNavigate } from "react-router-dom";


function App(){
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const userconnected = useSelector((state) => state.user.user);

  const location = useLocation();

  async function fetchAllUsers(){
    if(userconnected){
      let allUsers = await getAllUsers();
      dispatch(setUsers(allUsers))
    }
  }
  async function fetchAllMatch(){
    if(userconnected){
      let allMatch = await getAllMatch();
      dispatch(setMatch(allMatch))
    }
  }
  async function fetchAllProno(){
    if(userconnected){
      let allProno = await getAllProno();
      dispatch(setProno(allProno))
    }
  }
  async function fetchUserInformation(){
    const id = localStorage.getItem('idusername')
    if(id){
      let { data: classement, error } = await supabase.from('classement').select('*').eq('id', id)
      if(classement[0]){
        if(location.pathname === "/signin" || location.pathname === "/signup" || location.pathname === "/"){
          navigate("/home");
        };
        dispatch(setUser(classement[0]))
      }
      if(error)console.log(error);
    }
  }

  function fetchAll(){
    console.log("je fecth");
    fetchAllUsers();
    fetchAllMatch();
    fetchAllProno()
  }

  //Récuperer la liste de tous les utilisateurs pour le classement
  useEffect(() => {
    if(userconnected){fetchAll()}
  },[userconnected]);

  useEffect(() => {
    fetchUserInformation()
  },[]);

  // useEffect(() => {
  //   restartCron();
  //   const timer = setInterval(restartCron, 60000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // },[])

  //DETECTION D'UN CHANGEMENT SUR SUPABASE POUR REACTUALISER LE STORE
  const channelA = supabase.channel('schema-db-changes').on('postgres_changes',
    {
      event: '*',
      schema: 'public',
    },
    (payload) => {
      fetchAll()
    }
  ).subscribe()

  // Fonction à exécuter à chaque changement de route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //COMPONENT D'ERREUR
  function FallbackError(error) {
    return (
      <div role="alert">
        <p style={{ color: "red" }}>Une erreur est survenue.</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={FallbackError}>
        {userconnected ? <Component__Navbar/> : null}
        <Component__Intro/>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path='/signin' element={<Signin/>} />
            <Route path='/signup' element={<Signup/>} />
            {userconnected ? <Route path='/home' element={<Home/>} /> : null}
            {userconnected ? <Route path='/home/details/:id' element={<MatchStats/>} /> : null}
            {userconnected ? <Route path='/reglages' element={<Reglage/>} /> : null}
            {userconnected ? <Route path='/reglages/reglement' element={<Reglement/>} /> : null}
            {userconnected ? <Route path='/reglages/modifier-profil' element={<UpdateProfil/>} /> : null}
            {userconnected ? <Route path='/reglages/fonctionnement' element={<Fonctionnement/>} /> : null}
            {userconnected ? <Route path='/classement' element={<Classement/>} /> : null}
            {userconnected ? <Route path='/matchs' element={<Match/>} /> : null}
            {userconnected ? <Route path='/matchs/:id' element={<Matchdetail/>} /> : null}
            {userconnected && userconnected.role === 'admin' ? <Route path='/admin' element={<Admin/>} /> : null}
          </Routes>
        </AnimatePresence>
      </ErrorBoundary>
    </>
  );
}

export default App;

