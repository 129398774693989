import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../reducers/userSlice";
import usersReducer from "../reducers/usersSlice";
import matchSlice from "../reducers/matchSlice";
import pronoSlice from "../reducers/pronoSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    match: matchSlice,
    prono: pronoSlice,
  },
})