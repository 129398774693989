import React, { useState, useRef } from "react";
import "../../style/Style__Register.scss"
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../js/http-common";

//PASSWORD
import bcrypt from "bcryptjs-react";
//STORE
import {useDispatch } from 'react-redux'
import { setUser } from "../../reducers/userSlice";

function Signup(){
    //REF
    const hiddenFileInput = useRef(null);

    //NAVIGUATION
    const navigate = useNavigate();
    //STORE
    const dispatch = useDispatch()

    //STATE
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        confirmPassword: "",
        filLocation: ""
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const [buttonMessage, setButtonMessage] = useState('Créer mon compte');
    const [fileMessage, setFileMessage] = useState('Sélectionner une photo de profil (optionnel)');
    const [isLoading, setIsLoading] = useState(false);

    //FUNCTION
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null)
        const { username, password, confirmPassword } = formData;

        if(password !== confirmPassword){
            setErrorMessage('Les mots de passe ne correspondent pas')
            return;
        }
        setButtonMessage('Chargement...')
        const { data, error } = await getUsers(username);
        if (error) {
            setErrorMessage("Une erreur s'est produite lors de la vérification du nom d'utilisateur.");
            setButtonMessage('Créer mon compte')
            return;
        } 
        if (data && data.length > 0) {
            setErrorMessage('Ce nom d\'utilisateur existe déjà');
            setButtonMessage('Créer mon compte')
        } 
        else{
            setButtonMessage('Inscription en cours')
            const salt = bcrypt.genSaltSync(10);
            const hashedPassword = bcrypt.hashSync(formData.password, salt);
            let fileUrl = "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png"
            if(formData.filLocation){fileUrl = "https://ktwovnnuasgfxcvukqqx.supabase.co/storage/v1/object/public/avataruser/"+formData.filLocation}
            const {data, error} = await supabase.from('classement').insert({ username: formData.username, password: hashedPassword, avatar: (fileUrl) },).select()
            if (error) {
                setErrorMessage("Une erreur s'est produite lors de l'inscription.");
                setButtonMessage('Créer mon compte')
            } else if(data && data.length > 0){
                console.log('Inscription réussite', data)
                window.localStorage.setItem('idusername', data[0].id)
                navigate("/home");
                dispatch(setUser(data[0]))
                
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUpdate = async (e) => {
        setIsLoading(true)
        const file = e.target.files[0];
        const maxSizeInBytes = 1 * 1024 * 1024;

        setFileMessage("Vérification en cours...")
        if (!file) {return;}
        if (!["image/jpeg", "image/png"].includes(file.type)) {
            console.error("Le format de fichier n'est pas pris en charge. Veuillez choisir une image JPEG ou PNG.");
            setFileMessage("Le format de ce fichier n'est pas pris en charge.")
            setIsLoading(false)
            return;
        }
        if (file.size > maxSizeInBytes) {
            console.error("La taille du fichier est trop grande. Veuillez choisir un fichier de moins de 1,5 Mo.");
            setFileMessage("Image trop volumineuse.")
            setIsLoading(false)
            return;
        }
        try {
            setFileMessage("Upload en cours...")
            const { data, error } = await supabase.storage
                .from("avataruser")
                .upload(`avatars/${file.name}`, file, { cacheControl: "3600"});
        
            if (error) {
                console.error("Error uploading file:", error.message);
                setFileMessage("Une erreur est survenue.")
                setIsLoading(false)
            } else {
                console.log("File uploaded:", data);
                setFormData({ ...formData, filLocation: `avatars/${file.name}` });
                setFileMessage(`${file.name}`)
                setIsLoading(false)
                
            }
        } catch (error) {
          console.error("Error uploading file:", error.message);
          setFileMessage("Une erreur est survenue.")
          setIsLoading(false)
        }
      };

    async function getUsers(username) {
        let { data: classement, error } = await supabase.from('classement').select('username').eq('username', username)
        return ({ data: classement, error });
    }

    const HandleClickFile = (event) => {
        hiddenFileInput.current.click();
    };

    return (
      <div className='Register'>
        {isLoading ? <div>Chargement</div> : null}
        <h1 className="MainH1">Bienvenue sur PrimeProno !</h1>
        <p>Crée ton compte pour accéder au reste de l'application.</p>

        <form className="Register__form Mainform" onSubmit={handleSubmit}>
            <fieldset>
                <input required type="texte" name="username" placeholder="Nom d'utilisateur*" value={FormData.username} onChange={handleChange}/>
            </fieldset>
            <fieldset>
                <input required type="password" name="password" placeholder="Mot de passe*" value={FormData.password} onChange={handleChange}/>
            </fieldset>
            <fieldset>
                <input required type="password" name="confirmPassword" placeholder="Confirmer mon mot de passe*" value={FormData.confirmPassword} onChange={handleChange}/>
            </fieldset>
            <fieldset>
                <div className="inputbutton" onClick={HandleClickFile}>
                    <span>{fileMessage}</span>
                </div>
            </fieldset>
            <button type="submit">{buttonMessage}</button>
        </form>
        
        <Link className="Register__link" to='/signin'>J'ai déja un compte.</Link><br/>
        <span className="Register__error">{errorMessage}</span>
        <input ref={hiddenFileInput} className="upload_input" type="file" name="avatar" placeholder="Choisir une photo de profil" onChange={handleUpdate}/>
      </div>
    );
}
  
export default Signup;