import { supabase } from "../js/http-common";

export async function getAllUsers(){
    let { data: classement, error } = await supabase.from('classement').select('*')
    if(error)console.log(error)
    return classement
}

export async function getAllMatch(){
    let { data: matchs, error } = await supabase.from('matchScrap').select('*').order('date', { ascending: false })
    
    if(error)console.log(error)
    return matchs
}

export async function getAllProno(){
    let { data: pronos, error } = await supabase.from('pronos').select('*')
    if(error)console.log(error)
    return pronos
}

