import React, { useEffect, useState } from 'react'
import "../../style/Style__MatchStats.scss"
import Component__MatchStat__Result from './Component__MatchStat__Result'
import { useSelector } from "react-redux/es/hooks/useSelector";

const Component__MatchStat = (props) => {
    const matchlist = useSelector((state) => state.match.match);
    const [teamMatchList, setTeamMatchList] = useState(null)

    useEffect(()=>{
        if(matchlist){
            var filteredMatchList = matchlist.filter(function(item) {
                return (item.equipeA === props.team || item.equipeB === props.team) && item.is_play;
            });
            filteredMatchList.sort(function(a, b) {
                var dateA = new Date(a.date);
                var dateB = new Date(b.date);
                return dateB - dateA;
            });
            filteredMatchList.slice(0, 5);
            setTeamMatchList(filteredMatchList)
        }
    },[matchlist])
    return (
        <div className='MatchStats__teamresult'>
            <div className='MatchStats__teamresult__left'>
                <span>{props.team}</span>
            </div>

            {teamMatchList && teamMatchList.length > 0 ? 
                <div className='MatchStats__teamresult__right'>
                    {teamMatchList.slice(-5).map(item => (
                        <Component__MatchStat__Result key={item.id} data={item} team={props.team}/>
                    ))}
                </div>
            
            : <span style={{fontSize: "10px"}}>Pas d'historique</span>
            }
            
            
        </div>
    )
}

export default Component__MatchStat
