function Fonctionnement(){
    return (
      <div className='Reglage'>
        <h1 className="MainH1">Fonctionnement<br/><br/></h1>
        <p className="Maindescription">
          Page en cours de construction, réessayer plus tard.
        </p>
      </div>
    );
}
  
export default Fonctionnement;