import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../style/Style__Reglages.scss"
import ComponentProfil from "./Component__Profil";
import Component__Modal from "./Component__Modal";

//STORE
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from "../../reducers/userSlice"

function Reglage(){

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const userstore = useSelector((state) => state.user.user);

    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleDeco = async (e) => {
        navigate("/signin");
        window.localStorage.removeItem("idusername")
        dispatch(setUser(null))
    }; 

    const toggleModal = () =>{
      console.log('tot');
      setIsModalOpen(!isModalOpen)
    }



    return (
      <div className='Reglage'>
        <h1 className="MainH1">Mon profil</h1>
        {isModalOpen ? <Component__Modal closeFunction={toggleModal} confirmFunction={handleDeco} /> : null}
        <div className="Reglage__profil">
          <div className="Reglage__profil__head">
            <div className="Reglage__profil__head__picture">
              <img alt={userstore.username} src={userstore.avatar}/>
            </div>
            <p className="FSemibold">{userstore.username}</p>
            <div className="OptionDesktop hideOnMobile">
                <Link className='hideOnMobile__item' to="/reglages/reglement">Règlement</Link>
                <div className='hideOnMobile__item deco' onClick={toggleModal}>Me déconnecter</div>
            </div>
          </div>
          <div className="Reglage__profil__stats">
            <div className="Reglage__profil__stats__bloc">
              <p className="Reglage__profil__stats__bloc__title FMedium">Mes statistiques</p>
              <ComponentProfil label="Score" ctg="score" />
              <ComponentProfil label="Score de seum" ctg="score_seum" />
              <ComponentProfil label="Coef. de seum" ctg="seum" />
              <ComponentProfil label="Pronos parfaits" ctg="score_exactprono"/>
              <ComponentProfil label="Pronos bons" ctg="bon_prono"/>
            </div>
          </div>
        </div>
        <div className="Reglage__container showOnMobile">
            <p className="Reglage__profil__stats__bloc__title FMedium">Options</p>
            <Link className='Reglage__container__item' to="/reglages/reglement">Règlement</Link>
            <Link className='Reglage__container__item' to="/reglages/fonctionnement">Fonctionnement de l'app</Link>
            <Link className='Reglage__container__item' to="/reglages/modifier-profil">Modifier mon profil</Link>
            <div className='Reglage__container__item deco' onClick={toggleModal}>Me déconnecter</div>
        </div>
      </div>
    );
}
  
export default Reglage;