import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

function NavbarLink(props) {
  const location = useLocation();
  const imgSrc = location.pathname.includes(props.link) ? `${props.img.replace('.svg', '_blue.svg')}` : props.img;

  return (
    <div>
      <Link className='Navbar__item' to={props.link}>
        <img alt={props.textlink} src={imgSrc} />
        <span className={` ${location.pathname.includes(props.link) ? "activelink" : ""}`}>{props.textlink}</span>
      </Link>
    </div>
  );
}

export default NavbarLink;
