import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../style/Style__Match.scss"
import { formatDate } from "../Home/helpers";

//STORE
import { useSelector } from "react-redux/es/hooks/useSelector";

//COMPONENT
import Component_Match from "./Component_Match";


function Match(){
    const matchlist = useSelector((state) => state.match.match);
    const [isMatchs, setIsMatchs] = useState(null)
    const [allMatch, setAllMatch] = useState(null)
    const [groupedMatches, setGroupedMatches] = useState(null);


    useEffect(() => {
      if (matchlist) {
        let filteredArr = [];
  
        matchlist.forEach(item => {
          if (item.is_play === true) {
            filteredArr.push(item);
          }
        });
        setAllMatch(filteredArr);
      }
    }, [matchlist]);

    useEffect(() => {
      // Cette fonction regroupe les matches par date
      if (allMatch) {
        const grouped = {};
        allMatch.forEach(match => {
          if (!grouped[match.date]) {
            grouped[match.date] = [];
          }
          grouped[match.date].push(match);
        });
        setGroupedMatches(grouped);
        Object.keys(grouped).length === 0 ? setIsMatchs(false): setIsMatchs(true);
      }
    }, [allMatch]);

    return (
      
      <div className='Match'>
        <h1 className="MainH1">Liste des matchs</h1>
        <p className="Maindescription">Retrouve ici la liste de tous les matchs terminés. Si le match est en <span className="green">vert</span> c'est que ton pari était parfait ! <br/>S'il est en <span className="orange">jaune</span> c'est que tu as trouvé le bon gagnant.</p>
        {groupedMatches ? 
            <div className="Match__container">
                {Object.keys(groupedMatches).map(date => (
                  <div className="Match__container__date" key={date}>
                    <p>{formatDate(date)}</p>
                    <div className="Match__container__blocmaths">
                      {groupedMatches[date].map(match => (
                        <div  key={match.id}>
                          <Link key={match.id} to={'/matchs/' + match.id}><Component_Match data={match} /></Link>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
        : <div>Chargement...</div>} 
        {!isMatchs ? 
        <div className="Match__end">
          <img src="/media/eyes.svg" alt="terrain" />
          <p>Mince, il n'y a pas encore de matchs terminés pour le moment, reviens plus tard. </p>
        </div>
         : null}
      </div>
    );
  }
  
  export default Match;