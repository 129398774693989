import { createSlice } from '@reduxjs/toolkit'

const matchSlice = createSlice({
  name: "match",
  initialState:{
    match: null,
  },
  reducers: {
    setMatch: (state, action) => {
      state.match = action.payload;
    },
  },
});

export const { setMatch } = matchSlice.actions;
export default matchSlice.reducer;