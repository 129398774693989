import React from 'react';
import Component__Navbar__Link from './Component__Navbar__Link';
import '../../style/Style__Navbar.scss';
import { useSelector } from 'react-redux'

function Component__Navbar(props) {

      const userconnected = useSelector((state) => state.user.user);
      return(
        <div className='Navbar' id='js_navabar'>
          <Component__Navbar__Link link="/home" textlink='Accueil' img={'./media/icon/icon_home.svg'}/>
          <Component__Navbar__Link link="/matchs" textlink='Matchs' img={'./media/icon/icon_list.svg'}/>
          <Component__Navbar__Link link="/classement" textlink='Classement' img={'./media/icon/icon_classement.svg'}/>
          <Component__Navbar__Link link="/reglages" textlink='Profil' img={'./media/icon/icon_profil.svg'}/>
          {userconnected.role === "admin" ? <Component__Navbar__Link link="/admin" textlink='Admin' img={'./media/icon/icon_param.svg'}/> : null}
        </div>
      ) ;
    
  }

export default Component__Navbar