import React, { useEffect, useState, useRef } from "react";
import Chart, { Legend } from "chart.js/auto";
import { Bar } from "react-chartjs-2";

const Component__Chart = (props) => {
    const chartContainerRef = useRef(null);

    const [labels, setLabels] = useState([])
    const [scores, setScores] = useState([])
    const [chartAxis, setChartAxis] = useState('x')

    const [chartSize, setChartSize] = useState({
        width: "800px",
        height: "455px"
    })

    const [rerender, setRerender] = useState(false);

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Classement",
                backgroundColor: "#272100",
                borderColor: "#E8C300",
                borderWidth: 2,
                borderRadius: 5,
                data: scores,
            },
        ],
        options: {
            responsive: true,
        },
    };

    const handleWindowResize = () => {
        const chartContainer = chartContainerRef.current;
        const isMobile = window.innerWidth <= 768;
        if (isMobile) {setChartAxis('y')} 
        else {setChartAxis('x')}
        if (chartContainer) {
            const width = chartContainer.offsetWidth;
            console.log(width);
            setChartSize({ ...chartSize, width: width + "px" });
        }

    };

    useEffect(()=>{
        setRerender(!rerender);
    },[chartSize])

    useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
    }, []);

    useEffect(()=>{
        if(props.data && props.valueToShow){
            const labels = props.data.map(user => user.username);
            let scores = []
            let title;

            if(props.valueToShow == 'score'){
                scores = props.data.map(user => user.score);
            }
            if(props.valueToShow == 'seum'){
                scores = props.data.map(user => user.score_seum);
            }
            if(props.valueToShow == 'exactprono'){
                scores = props.data.map(user => user.score_exactprono);
            }
            if(props.valueToShow == 'bonprono'){
                scores = props.data.map(user => user.bon_prono);
            }
            setLabels(labels)
            setScores(scores)
        }
        handleWindowResize()
    },[props.data, props.valueToShow])

    return (
        <div className="Classement__chart__container" ref={chartContainerRef}>
            <Bar data={data} width={chartSize.width} options={{ indexAxis:chartAxis, maintainAspectRatio: false, responsive: true, plugins:{legend:{display:false}} }} key={rerender}/>
        </div>
    );
};

export default Component__Chart;
