import React, { useEffect, useState } from 'react';


function CompCote(props) {

  const [prono, setProno] = useState({
    coteA: false,
    coteN: false,
    coteB: false,
  })

  useEffect(() => {
    let finalcoteA, finalcoteN, finalcoteB;
    if(props.score.scoreA > props.score.scoreB)finalcoteA = true
    else if(props.score.scoreA === props.score.scoreB)finalcoteN = true
    else if(props.score.scoreA < props.score.scoreB)finalcoteB = true
    setProno({
      coteA: finalcoteA,
      coteN: finalcoteN,
      coteB: finalcoteB,
    })


}, [props.data, props.score]);

  return (
    <div className='Home__container__item__center__bottom Match__container__item__center__bottom'>
        <span className={` ${prono.coteA ? "active_cote" : ""}`}>{props.data.coteA}</span>
        <span className={` ${prono.coteN ? "active_cote" : ""}`}>{props.data.coteN}</span>
        <span className={` ${prono.coteB ? "active_cote" : ""}`}>{props.data.coteB}</span>
    </div>
  );
}

export default CompCote;
