export function formatDate(inputDate) {
    // Créez un objet Date à partir de la chaîne d'entrée
    const dateParts = inputDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
  
    // Tableau des noms de mois
    const monthNames = [
      "Janvier", "Février", "Mars",
      "Avril", "Mai", "Juin", "Juillet",
      "Août", "Septembre", "Octobre",
      "Novembre", "Décembre"
    ];
  
    // Convertissez le mois en format texte
    const monthName = monthNames[parseInt(month) - 1];
  
    // Format final
    const formattedDate = day + ' ' + monthName + ' ' + year;
  
    return formattedDate;
  }
  