import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { supabase } from '../../js/http-common';
import "../../style/Style__Home.scss"
import CompCote from '../../components/Cote/Component__Cote';

import { getAllProno } from '../../store/data';
import { setProno } from '../../reducers/pronoSlice';

function Component_Home(props) {

    const dispatch = useDispatch()

    const userstore = useSelector((state) => state.user.user);
    const pronolist = useSelector((state) => state.prono.prono);

    const [pronoInfo, setPronoInfo] = useState({
        scoreA: '',
        scoreB: '',
        pronoId: null
    })
    const [pronoFind, setPronoFind] = useState(false)
    const [drapeau, setDrapeau] = useState({
        A: null,
        B: null
    })

    const [isLoading, setIsLoading] = useState(false)
    const [formattedDate, setFormattedDate] = useState('')


    const handleFocus = async (e) => {
        const { name, value } = e.target;
        setPronoInfo({
            ...pronoInfo, [name]: ''
        })
        const navbar = document.getElementById('js_navabar')
        navbar.classList.add('inactive')
    }; 

    const handleBlur = async (e) => {
        const navbar = document.getElementById('js_navabar')
        navbar.classList.remove('inactive')
    }; 

    const handleChangeProno = async (e) => {
        const { name, value } = e.target;
        const updatedPronoInfo = { ...pronoInfo, [name]: value };
        console.log(updatedPronoInfo);
        setPronoInfo(updatedPronoInfo)

        if (updatedPronoInfo.scoreA !== '' && updatedPronoInfo.scoreB !== '') {
            props.toggle(true)
            await updateProno(updatedPronoInfo);
            props.toggle(false)
        }

        else {
            console.log('Il manque une valeur');
        }
        e.target.blur();
    };
    

    const updateProno = async (updatedPronoInfo) => {
        //ADAPTATION STRING TON NUMBER POUR SUPABASE
        let scoreA, scoreB;
        
        if(typeof updatedPronoInfo.scoreA === 'number') {scoreA = updatedPronoInfo.scoreA}
        else{scoreA = parseInt(updatedPronoInfo.scoreA) }
        if(typeof updatedPronoInfo.scoreB === 'number') {scoreB = updatedPronoInfo.scoreB}
        else{scoreB = parseInt(updatedPronoInfo.scoreB)}

        console.log(scoreA, scoreB);
        
        //UPDATE SI LE PRONO EXISTE DEJA
        if (pronoFind) {
            console.log("update");
            console.log(pronoFind, pronoInfo);

            const { data, error } = await supabase.from('pronos').update({ 
                scoreA: scoreA, 
                scoreB: scoreB, 
            }).eq('id', updatedPronoInfo.pronoId).select()

            if(error)console.log(error);
            else {
                //SI UPDATE REUSSITE RECHARGEMENT DU STORE
                console.log(data)
                fetchAllProno()
            } 
        }
        //SINON CREATION
        else{
            console.log(pronoFind, pronoInfo);
            console.log("création")
            const { data, error } = await supabase.from('pronos').insert({ 
                scoreA: scoreA, 
                scoreB: scoreB, 
                username: userstore.username,
                id_match: props.data.id,
                id_user: userstore.id,
            }).select()

            if(error)console.log(error);
            
            else {
                //SI CREATION REUSSITE RECHARGEMENT DU STORE
                console.log(data)
                fetchAllProno()
            } 
        }
        //CHARGEMENT SUR OFF, NOUVELLE REQUETE POSSIBLE
        //setIsLoading(false)
    };

    async function findProno(){
        let pronoFind = pronolist.find(prono => prono.id_user === userstore.id && prono.id_match === props.data.id);
        //console.log(pronoFind);
        if(pronoFind){
            setPronoFind(true)
            setPronoInfo({
                scoreA: pronoFind.scoreA,
                scoreB: pronoFind.scoreB,
                pronoId: pronoFind.id,
            })
        }
    }

    async function fetchAllProno() {
        try {
          let allProno = await getAllProno();
          dispatch(setProno(allProno));
          
        } catch (error) {
          console.log(error);
        }
      }

      useEffect(() => {
        findProno()
      },[pronolist])

    function HandleDrapeau(){
        // let linkDrapeauA = "https://flagcdn.com/"+props.data.drapeauA+".svg"
        // let linkDrapeauB = "https://flagcdn.com/"+props.data.drapeauB+".svg"
        let linkDrapeauA = props.data.drapeauA+".png"
        let linkDrapeauB = props.data.drapeauB+".png"

        setDrapeau({
            A: linkDrapeauA,
            B: linkDrapeauB
        })
    }

    //LANCEMENT DE LA PAGE
    useEffect(() => {
        findProno()
        HandleDrapeau()
    }, [props.data.id, userstore.id]);

    // useEffect(() => {
    //     console.log(isLoading);
        
    // }, [isLoading]);

    const handleInputChange = (e) => {
        e.preventDefault();
      };

    return (
        <div>
            <div className={`Home__container__item ${props.data.is_bonus ? "bonusActive" : ""}`}>
                <div className='Home__container__item__left'>
                    <div className='Home__container__item__left__drapeau'>
                        <img alt={props.data.equipeA} src={drapeau.A}/>
                    </div>
                    <span>{props.data.equipeA}</span>
                </div>
                <div className='Home__container__item__center'>
                    <div className='Match__container__item__center__top'>{props.data.heure}</div>
                    <div className='Home__container__item__center__center'>
                        <input type='number' className='FMedium' name='scoreA' max="9" value={pronoInfo.scoreA} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChangeProno} onClick={handleInputChange}  />
                        <input type='number' className='FMedium' name='scoreB' max="9" value={pronoInfo.scoreB} onFocus={handleFocus} onChange={handleChangeProno} onBlur={handleBlur} onClick={handleInputChange}/>
                    </div>
                    <CompCote data={props.data} score={pronoInfo}/>
                </div>
                <div className='Home__container__item__right'>
                    <div className='Home__container__item__right__drapeau'>
                        <img alt={props.data.equipeB} src={drapeau.B}/>
                    </div>
                    <span>{props.data.equipeB}</span>
                </div>
            </div>
        </div>
        
    );
}

export default Component_Home;
