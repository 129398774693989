import React, { useEffect, useState } from "react";
import "../../style/Style__Classement.scss"
import { Chart } from "chart.js";
import { CategoryScale } from "chart.js";

//STORE
import { useSelector } from "react-redux/es/hooks/useSelector";

//COMPONENT
import Component__Rank from "./Component__Rank";
import Component__Chart from "./Component__Chart";

Chart.register(CategoryScale);

function Classement(){

    const userlist = useSelector((state) => state.users.users);
    const [allUsers, setAllUsers] = useState(null)
    const [showChart, setShowChart] = useState(false)
    const [valueToShow, setValueToShow] = useState('score')
    const [showChartText, setShowChartText] = useState('Graphique')

    useEffect(() => {
        if (userlist) {
          const sortedUsers = [...userlist];
          sortedUsers.sort((a, b) => b.score - a.score);
          setAllUsers(sortedUsers);
        }
    }, [userlist]);

    const HandleChangeValue = (e) => {
        let newFilter = e.target.dataset.filter;
        let sortedUsers = [...allUsers];

        if(newFilter == 'score'){sortedUsers.sort((a, b) => b.score - a.score);}
        if(newFilter == 'seum'){sortedUsers.sort((a, b) => b.score_seum - a.score_seum);}
        if(newFilter == 'exactprono'){sortedUsers.sort((a, b) => b.score_exactprono - a.score_exactprono);}
        if(newFilter == 'bonprono'){sortedUsers.sort((a, b) => b.bon_prono - a.bon_prono);}
        setValueToShow(newFilter);
        setAllUsers(sortedUsers)
    }

    const HandleChangeViews = () =>{
      setShowChart(!showChart)
      if(showChartText == "Graphique"){setShowChartText('Tableau')}
      if(showChartText == "Tableau"){setShowChartText('Graphique')}
    }

    return (
      
      <div className='Classement'>
        <h1 className="MainH1">Classement</h1>
        <div className="Classement__option">
            <div className="Classement__option__left">
              <div className={`Classement__option__left__item ${valueToShow == 'score' ? "Classement__option__left__active" : ""}`} data-filter="score" onClick={HandleChangeValue}>Points</div>
              <div className={`Classement__option__left__item ${valueToShow == 'seum' ? "Classement__option__left__active" : ""}`} data-filter="seum" onClick={HandleChangeValue}>Seum</div>
              <div className={`Classement__option__left__item ${valueToShow == 'bonprono' ? "Classement__option__left__active" : ""}`} data-filter="bonprono" onClick={HandleChangeValue}>Bons pronos</div>
              <div className={`Classement__option__left__item ${valueToShow == 'exactprono' ? "Classement__option__left__active" : ""}`} data-filter="exactprono" onClick={HandleChangeValue}>Scores exacts</div>
            </div>
            <div className="Classement__option__right">
              <div className="Classement__option__right__item" onClick={HandleChangeViews}>
                {showChartText === "Graphique" ?
                  <img alt={showChartText} src="/media/icon/icon_graph.svg" />
                :
                  <img alt={showChartText} src="/media/icon/icon_table.svg" />
                }
                <span>{showChartText}</span>
              </div>
            </div>
        </div>

        {allUsers && !showChart ? 
            <div className="Classement__tableau">
                {allUsers.map((user, index) => (
                    <Component__Rank key={index} rank={index+1} name={user.username} valueToShow={valueToShow} avatar={user.avatar} score={user.score} seum={user.seum} scoreseum={user.score_seum} exactprono={user.score_exactprono} bonprono={user.bon_prono}/>
                ))}
            </div>
        : null} 

        {allUsers && showChart ? 
            <div className="Classement__chart">
                <Component__Chart data={allUsers} valueToShow={valueToShow}/>
            </div>
        : null} 

      </div>
    );
  }
  
  export default Classement;