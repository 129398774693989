import React, { useEffect } from 'react'
import '../../style/Style__Intro.scss';
import SplitType from 'split-type';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

const Component__Intro = () => {
  useEffect(()=>{
    const SplitTitle = new SplitType('.gsapSplit', { charClass: 'chartTitle' })
    if(SplitTitle){
      const tl = gsap.timeline()
      gsap.fromTo('.Intro__layer', {clipPath:"polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)"}, {clipPath:"polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: 1, ease: CustomEase.create("custom", "M0,0 C0.266,0.03 0.443,0.121 0.5,0.5 0.555,0.887 0.776,0.977 1,1 "), delay: 0.2 })
      gsap.fromTo('.chartTitle',{yPercent: 100,}, {yPercent:0,stagger: 0.025,duration: 0.6, ease: "power3.inOut", delay: 1.2})
      gsap.fromTo('.credit',{opacity: 0,}, {opacity:1,duration: 1, delay: 1.8})
      gsap.fromTo('.Intro', {clipPath:"polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)"}, {clipPath:"polygon(0% 0%, 100% 0, 100% 0%, 0% 0%)", duration: 1, ease: CustomEase.create("custom", "M0,0 C0.266,0.03 0.443,0.121 0.5,0.5 0.555,0.887 0.776,0.977 1,1 "), delay: 2.3})
    }
  },[])

  return (
    <div className='Intro'>
      <div className='Intro__layer'>
        <div className='Intro__layer__container'>
          <p className='FSemibold gsapSplit'>Prime Prono</p>
        </div>
        <p className='credit'>Dev. by Louis Mony</p>
      </div>
    </div>
  )
}

export default Component__Intro
