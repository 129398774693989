import React from 'react'

const Component__Admin__Loader = () => {
  return (
    <div className='GlobalLoader'>
      Traitement en cours ...
      <span className="loaderspan"></span>
    </div>
  )
}

export default Component__Admin__Loader
