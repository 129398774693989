import { supabase } from "../../../js/http-common";

export async function globalUpdateProno(data, pronostore, userstore){
    
    //SI SCORE A ET B REMPLIE ET QUE LE MATCH EST JOUÉ
    if(data.scoreA !== '' && data.scoreB !== '' && data.statut === true){
        
        //RÉCUPÉRATION DE TOUS LES PRONOS DU MATCH
        const pronolist = pronostore.filter(prono => prono.id_match === data.id);

        for (const prono of pronolist) {
            await traitementGlobalUpdateProno(data, prono, userstore)
        }
        await updateMatchLine(data.id, data.scoreA, data.scoreB)
    }
    else if(data.statut === false){
        console.log('match pas finis');
    }
}

async function traitementGlobalUpdateProno(data, prono, userstore){
    let userFind = userstore.find(user => user.id === prono.id_user);
            
    if(userFind){
        //STOCKAGE DES SCORE GLOBAL DU USER SANS CEUX DU PRONOS
        let userScore = userFind.score - prono.result
        let userScoreSeum = userFind.score_seum - prono.result_seum
        let userScoreExact = userFind.score_exactprono - prono.result_exact
        let userScoreBon = userFind.bon_prono - prono.result_bon

        //console.log('USER : '+prono.username, 'SCORE : '+userScore, 'SCORESEUM : '+userScoreSeum, 'SCOREEXACT : '+userScoreExact, 'SCOREBON : '+userScoreBon);

        //RECUPERE LE SCORE DE CHAQUE CATÉGORIE POUR LE PRONO
        const scoreCalculated = calculScore(data, prono)

        // console.log(prono.username+" a parié "+prono.scoreA+" - "+prono.scoreB)
        // console.log("le resultat et "+data.scoreA+" - "+data.scoreB)
        // console.log(prono.username+" a marqué "+scoreCalculated.result+"pts")
        console.log(prono.username+" a marqué "+scoreCalculated.resultSeum+"pts de seum")
        // console.log(prono.username+" a marqué "+scoreCalculated.resultBon+"pts de bon")
        // console.log(prono.username+" a marqué "+scoreCalculated.resultExact+"pts d'exact")

        //CALCUL NOUVEAU SCORE USER
        let newUserScore = userScore + scoreCalculated.result
        let newUserScoreSeum = userScoreSeum + scoreCalculated.resultSeum
        let newUserScoreExact = userScoreExact + scoreCalculated.resultExact
        let newUserScoreBon = userScoreBon + scoreCalculated.resultBon

        //console.log('USER : '+prono.username, 'NEW SCORE : '+newUserScore, 'NEW SCORESEUM : '+newUserScoreSeum, 'NEW SCOREEXACT : '+newUserScoreExact, 'NEW SCOREBON : '+newUserScoreBon);

        // UPDATE LE PRONO
        await updatePronoLine(scoreCalculated, prono.id)

        // UPDATE LE USER
        await updateUserLine(prono.id_user, newUserScore, newUserScoreSeum, newUserScoreExact, newUserScoreBon)
    }
}

function calculScore(datamatch, dataprono){
    let matchScoreA = parseInt(datamatch.scoreA);
    let matchScoreB = parseInt(datamatch.scoreB);
    let pronoScoreA = dataprono.scoreA;
    let pronoScoreB = dataprono.scoreB;
    
    let result = 0;
    let resultSeum = 0;
    let resultExact = 0;
    let resultBon = 0;

    //SI SCORE PARFAIT
    if(matchScoreA === pronoScoreA && matchScoreB === pronoScoreB){
        if(matchScoreA > matchScoreB ){result = (datamatch.coteA)*2}
        else if(matchScoreA < matchScoreB ){result = (datamatch.coteB)*2}
        else if(matchScoreA === matchScoreB ){result = (datamatch.coteN)*2}
        resultSeum = 0;
        resultExact = 1;
        resultBon = 1;
    }

    //SI BON PRONO
    else if((matchScoreA > matchScoreB && pronoScoreA > pronoScoreB) || (matchScoreA < matchScoreB && pronoScoreA < pronoScoreB) || (pronoScoreA === pronoScoreB && matchScoreA === matchScoreB)){
        if(matchScoreA > matchScoreB ){result = datamatch.coteA}
        else if(matchScoreA < matchScoreB ){result = datamatch.coteB}
        else if(matchScoreA === matchScoreB ){result = datamatch.coteN}

        resultSeum = calculSeum(pronoScoreA, pronoScoreB, matchScoreA, matchScoreB, datamatch, result )
        resultExact = 0;
        resultBon = 1;
    }

    //SI MAUVAIS SCORE
    else{
        result = 0;
        resultSeum = calculSeum(pronoScoreA, pronoScoreB, matchScoreA, matchScoreB, datamatch, result )
        resultExact = 0;
        resultBon = 0;
        
    }
    if(datamatch.bonus){
        result = result*2;
        resultSeum = resultSeum*2
    }
    return {result, resultSeum, resultExact, resultBon}
}

function calculSeum(pronoScoreA, pronoScoreB, matchScoreA, matchScoreB, datamatch, result){

    let resultSeum = 0;

    //TABLEAUX DE TOUS LES SCORE POSSIBLE A UN BUT PRES
    const scoresProchesArr = scoresProches(matchScoreA, matchScoreB)
    const pronoArr = [pronoScoreA, pronoScoreB]

    //SI CONTIENT TRUE => A UN BUT PRES PRONO EXACT
    const contient = isDedans(scoresProchesArr,pronoArr)
    if(contient){
        if(pronoScoreA > pronoScoreB){
            resultSeum = (datamatch.coteA)*2
        }
        else if(pronoScoreA < pronoScoreB){
            resultSeum = (datamatch.coteB)*2
        }
        else if(pronoScoreA === pronoScoreB){
            resultSeum = (datamatch.coteN)*2
        }
    }

    return resultSeum
}

function scoresProches(scoreA, scoreB) {
    const scores = [];
    scores.push([scoreA + 1, scoreB]);
    scores.push([scoreA - 1, scoreB]);
    scores.push([scoreA, scoreB + 1]);
    scores.push([scoreA, scoreB - 1]);
    return scores;
}

function isDedans(arr, sousArr){
    const contient = arr.some(
        (element) => element.length === sousArr.length && element.every((val, index) => val === sousArr[index])
    );
    return contient
}


async function updatePronoLine(scoreData, pronoId){
    console.log('update prono');
    const { data, error } = await supabase
    .from('pronos')
    .update({ 
        result: scoreData.result,
        result_seum: scoreData.resultSeum,
        result_exact: scoreData.resultExact,
        result_bon: scoreData.resultBon,
    })
    .eq('id', pronoId)
    .select()

    if(error)console.log(error);
    if(data)console.log(data);
}

async function updateUserLine(userId, newUserScore, newUserScoreSeum, newUserScoreExact, newUserScoreBon){
    console.log('update user');
    let coefseum = (newUserScoreSeum / newUserScore).toFixed(2)
    if(coefseum<1){coefseum =1}
    if(coefseum === Infinity){coefseum=1}

    const { data, error } = await supabase
    .from('classement')
    .update({ 
        score: newUserScore,
        score_seum: newUserScoreSeum,
        seum: coefseum,
        score_exactprono: newUserScoreExact,
        bon_prono: newUserScoreBon,
    })
    .eq('id', userId)
    .select()

    if(error)console.log(error);
    if(data)console.log(data);
}

async function updateMatchLine(idmatch, scoreA, scoreB){
    console.log('update match');
    const { data, error } = await supabase
    .from('matchScrap')
    .update({ 
        scoreA: scoreA,
        scoreB: scoreB,
    })
    .eq('id', idmatch)
    .select()

    if(error)console.log(error);
    if(data)console.log(data);
}

export async function updateBonus(matchId, bonusBool){
    const { data, error } = await supabase
    .from('matchScrap')
    .update({ is_bonus: bonusBool })
    .eq('id', matchId)
    .select()

    if(data)console.log(data);
    if(error)console.log(error)

}