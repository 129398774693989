import React, { useState } from "react";
import "../../style/Style__Register.scss"
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../js/http-common";
import bcrypt from "bcryptjs-react";

//STORE
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from "../../reducers/userSlice";

function Signin(){

  const navigate = useNavigate();
  //STORE
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user.user);

  //STATE
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [buttonMessage, setButtonMessage] = useState('Me connecter');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null)
    setButtonMessage('Chargement...')

    const { username, password } = formData;
    const { data, error } = await getUsers(username);
    console.log(data);

    if(!data || data.length != 1 || !bcrypt.compareSync(password, data[0].password)){
      setErrorMessage("Le mot de passe ou le nom d'utilisateur est incorrecte.")
      setButtonMessage('Me connecter')
    }
    else{
      navigate("/home");
      dispatch(setUser(data[0]))
      window.localStorage.setItem('idusername', data[0].id)
    }
  }

  async function getUsers(username) {
    let { data: classement, error } = await supabase.from('classement').select('*').eq('username', username)
    return ({ data: classement, error });
  }

  return (
    <div className='Register'>
      <h1 className="MainH1">Bienvenue sur PrimeProno !</h1>
      <p>Connecte toi à ton compte pour accéder au reste de l'application.</p>

      <form className="Register__form Mainform" onSubmit={handleSubmit}>
          <fieldset>
              <input required type="texte" id="username" name="username" placeholder="Nom d'utilisateur" value={FormData.username} onChange={handleChange}/>
          </fieldset>
          <fieldset>
              <input required type="password" id="password" name="password" placeholder="Mot de passe" value={FormData.password} onChange={handleChange}/>
          </fieldset>
          <button type="submit">{buttonMessage}</button>
      </form>

      <Link className="Register__link" to='/signup'>Je n'ai pas encore de compte.</Link><br/>
      <span className="Register__error">{errorMessage}</span>
    </div>
  );
}
  
export default Signin;