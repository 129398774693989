import React, { useEffect, useRef, useState } from 'react'

const Component_MatchCompare_Chart = (props) => {
    const [itemWidth, setItemWidth] = useState("10px")

    useEffect(()=>{
        if(props.statMain >= props.statScnd){
            console.log('max');
            setItemWidth('150px')
        }
        else{
            let newWidth = Math.floor(150*(props.statMain/props.statScnd))
            if(newWidth < 30){newWidth = 30}
            setItemWidth(newWidth+"px")
        }
    },[props.statMain, props.statScnd])
    return (
        <div className={props.classNameProp}>
            <span>{props.spanText}</span>
            <div style={{ width: itemWidth}}>{props.statMain}</div>
        </div>
    )
}

export default Component_MatchCompare_Chart
