import React, { useEffect, useState } from 'react'
import "../../style/Style__MatchStats.scss"
import Component_MatchCompare_Chart from './Component_MatchCompare_Chart'

const Component__MatchCompare = (props) => {
    const [statTeam, setStatTeam] = useState({
        AwinNum: 0,
        AdrawNum: 0,
        AdefNum: 0,
        Agoal: 0,
        AgoalTaken: 0,
        BwinNum: 0,
        BdrawNum: 0,
        BdefNum: 0,
        Bgoal: 0,
        BgoalTaken: 0,
    })

    useEffect(()=>{
        if(props.data && props.data.length > 0){
            var filteredMatchList = props.data.filter(function(item) {
                return (item.equipeA === props.teamA || item.equipeB === props.teamA || item.equipeA === props.teamB || item.equipeB === props.teamB) && item.is_play;
            });

            console.log(filteredMatchList);

            let winA = 0 ;
            let defA = 0;
            let drawA = 0;
            let goalA = 0;
            let goalTakenA = 0;

            let winB = 0 ;
            let defB = 0;
            let drawB = 0;
            let goalB = 0;
            let goalTakenB = 0;

            filteredMatchList.forEach(item => {
                if(props.teamA === item.equipeA){
                    if(item.scoreA > item.scoreB){ winA++}
                    else if(item.scoreA === item.scoreB){ drawA++}
                    else if(item.scoreA < item.scoreB){ defA++}
                    goalA = goalA + item.scoreA
                    goalTakenA = goalTakenA + item.scoreB
                }
                if(props.teamA === item.equipeB){
                    if(item.scoreA > item.scoreB){ defA++}
                    else if(item.scoreA === item.scoreB){ drawA++}
                    else if(item.scoreA < item.scoreB){ winA++}
                    goalA = goalA + item.scoreB
                    goalTakenA = goalTakenA + item.scoreA
                }
                if(props.teamB === item.equipeB){
                    if(item.scoreA > item.scoreB){ defB++}
                    else if(item.scoreA === item.scoreB){ drawB++}
                    else if(item.scoreA < item.scoreB){winB++}
                    goalB = goalB + item.scoreB
                    goalTakenB = goalTakenB + item.scoreA
                }
                if(props.teamB === item.equipeA){
                    if(item.scoreA > item.scoreB){ winB++}
                    else if(item.scoreA === item.scoreB){ drawB++}
                    else if(item.scoreA < item.scoreB){ defB++}
                    goalB = goalB + item.scoreA
                    goalTakenA = goalTakenA + item.scoreB
                }
            });

            setStatTeam({
                AwinNum: winA,
                AdrawNum: drawA,
                AdefNum: defA,
                Agoal: goalA,
                AgoalTaken: goalTakenA,
                BwinNum: winB,
                BdrawNum: drawB,
                BdefNum: defB,
                Bgoal: goalB,
                BgoalTaken: goalTakenB,
            })
        }
    },[props.data])

    useEffect(() =>{
        
    },[setStatTeam])

    return (
        <div className='compare'>
            <div className='compare__left'>
                <div className='compare__left__head FSemibold'>{props.teamA}</div>
                <Component_MatchCompare_Chart classNameProp={"compare__left__item"} spanText={"V"} statMain={statTeam.AwinNum} statScnd={statTeam.BwinNum} />
                <Component_MatchCompare_Chart classNameProp={"compare__left__item"} spanText={"D"} statMain={statTeam.AdefNum} statScnd={statTeam.BdefNum} />
                <Component_MatchCompare_Chart classNameProp={"compare__left__item"} spanText={"N"} statMain={statTeam.AdrawNum} statScnd={statTeam.BdrawNum} />
                <Component_MatchCompare_Chart classNameProp={"compare__left__item"} spanText={"B"} statMain={statTeam.Agoal} statScnd={statTeam.Bgoal} />
                <Component_MatchCompare_Chart classNameProp={"compare__left__item"} spanText={"Bc"} statMain={statTeam.AgoalTaken} statScnd={statTeam.BgoalTaken} />
            </div>
            <div className='compare__right'>
                <div className='compare__right__head FSemibold'>{props.teamB}</div>
                <Component_MatchCompare_Chart classNameProp={"compare__right__item"} spanText={"V"} statMain={statTeam.BwinNum} statScnd={statTeam.AwinNum} />
                <Component_MatchCompare_Chart classNameProp={"compare__right__item"} spanText={"D"} statMain={statTeam.BdefNum} statScnd={statTeam.AdefNum} />
                <Component_MatchCompare_Chart classNameProp={"compare__right__item"} spanText={"N"} statMain={statTeam.BdrawNum} statScnd={statTeam.AdrawNum} />
                <Component_MatchCompare_Chart classNameProp={"compare__right__item"} spanText={"B"} statMain={statTeam.Bgoal} statScnd={statTeam.Agoal} />
                <Component_MatchCompare_Chart classNameProp={"compare__right__item"} spanText={"Bc"} statMain={statTeam.BgoalTaken} statScnd={statTeam.AgoalTaken} />
            </div>
        </div>
    )
}

export default Component__MatchCompare
