function Reglement(){
    return (
      <div className='Reglage'>
        <h1 className="MainH1">Réglement<br/><br/></h1>
        <p className="Maindescription">
        <span className="FSemibold">Il existe trois classements différents :</span><br/><br/>
        - Le classement par points : C’est le classement principal, il récompense le joueur le plus précis dans ses pronostics.<br/>
        - Le classement par seum, il récompense le joueur le plus malchanceux dans ses pronostics. Pour expliquer, le seum est le total de points qu’un joueur aurait pu obtenir sur l’ensemble de ses pronostics à un but près par match.<br/>
        - Le classement par pronostic exact, qui, comme son nom l’indique, récompense le joueur avec le plus de paris parfaits.<br/><br/>

        <span className="FSemibold">Comptage des points : </span><br/><br/>

        Si tu as pronostiqué les bons vainqueurs d’un match (ou le bon nul), tu remportes autant de points que la cote correspondante à ton pronostic. Exemple : Thomas a parié 2 à 0 pour la France, la France remporte le match 2 à 1. Le pronostic de Thomas est donc correct, il remporte 20 points car la cote de la France était de 20.<br/><br/>

        En cas de pronostic exact, tu empocheras le double de points, de même pour les matchs bonus, en plus ces multiplications sont cumulables ! Par exemple, si Thomas pronostique 2-1 sur une cote à 20, sur un match bonus, alors si le match se termine sur le score de 2-1, il marquera 20 x 2 x 2 = 80 points.<br/><br/>

        Si ton pronostic est mauvais, tu ne marques aucun point.<br/><br/>

        Pour le classement du seum, c’est un peu différent, on calcule le maximum de points qu’aurait pu rapporter ton pronostic à un but près sur le match. Par exemple, Thomas a parié 2-1 pour la France. Le match s’est finalement terminé sur le score de 1 - 1, il marque donc 0 point au classement classique, en revanche, à un but près, son pronostic aurait été parfait, il marque donc 20 (cote de la France sur le match) x 2 (car son pronostic aurait été exact) = 40 points au classement du seum.<br/><br/>

        Attention, la cote d’une équipe n’est jamais la même et varie en fonction de chaque match.<br/>
        </p>
      </div>
    );
}
  
export default Reglement;