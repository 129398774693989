import { createSlice } from '@reduxjs/toolkit'

const pronoSlice = createSlice({
  name: "prono",
  initialState:{
    prono: null,
  },
  reducers: {
    setProno: (state, action) => {
      state.prono = action.payload;
    },
  },
});

export const { setProno } = pronoSlice.actions;
export default pronoSlice.reducer;