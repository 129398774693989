import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../style/Style__MatchDetail.scss"
import Component_Match from "../Matchs/Component_Match";
import ComponentMatchDetailProno from "./Component__MatchDetailProno";


//STORE
import { useSelector } from "react-redux/es/hooks/useSelector";

function Matchdetail(){

    const params = useParams();
    const userlist = useSelector((state) => state.users.users);
    const matchlist = useSelector((state) => state.match.match);
    const pronolist = useSelector((state) => state.prono.prono);

    const [match, setMatch] = useState(null)
    const [allProno, setAllProno] = useState(null)
    const [drapeau, setDrapeau] = useState({
        A: null,
        B: null
    })

    useEffect(() => {
        let matchFind = matchlist?.find(match => match.id === params.id);
        if(matchFind)setMatch(matchFind)
    }, [matchlist]);

    useEffect(() => {
        if(match){
            HandleDrapeau()
            const Arr = []
            userlist.forEach(user => {
                let userprono = pronolist.find(prono => prono.id_match === params.id && prono.id_user == user.id);
                if(userprono){
                    Arr.push(userprono)
                }
            });
            setAllProno(Arr)
        }
    }, [match]);

    function HandleDrapeau(){
        // let linkDrapeauA = "https://flagcdn.com/"+match.drapeauA+".svg"
        // let linkDrapeauB = "https://flagcdn.com/"+match.drapeauB+".svg"
        let linkDrapeauA = match.drapeauA+".png"
        let linkDrapeauB = match.drapeauB+".png"


        setDrapeau({
            A: linkDrapeauA,
            B: linkDrapeauB
        })
    }

    return (
      
      <div className='Matchdetail'>
        <h1 className="MainH1">Détail du match</h1>
        {match ? 
            <div className="Matchdetail__details">
            <div className="Matchdetail__details__main">
                <div className="Matchdetail__details__main__left">
                    <span>{match.equipeA}</span>
                    <div className="Matchdetail__details__main__left__drapeau">
                        <img src={drapeau.A} alt="drapeau" />
                    </div>
                    
                </div>
                <div className="Matchdetail__details__main__center">
                    <div></div>
                    <div className="Matchdetail__details__main__center__top">
                        <div className="Matchdetail__details__main__center__top__score">
                            <span className="FSemibold">{match.scoreA}</span>
                        </div>
                        <div className="Matchdetail__details__main__center__top__score">
                            <span className="FSemibold">-</span>
                        </div>
                        <div className="Matchdetail__details__main__center__top__score">
                            <span className="FSemibold">{match.scoreB}</span>
                        </div>
                    </div>
                    <div className="Matchdetail__details__main__center__bot">
                        <div className="Matchdetail__details__main__center__bot__cote">
                            <span>{match.coteA}</span>
                        </div>
                        <div className="Matchdetail__details__main__center__bot__cote">
                            <span>{match.coteN}</span>
                        </div>
                        <div className="Matchdetail__details__main__center__bot__cote">
                            <span>{match.coteB}</span>
                        </div>
                    </div>
                </div>
                <div className="Matchdetail__details__main__right">
                    <span>{match.equipeB}</span>
                    <div className="Matchdetail__details__main__left__drapeau">
                        <img src={drapeau.B} alt="drapeau" />
                    </div>
                    
                </div>
            </div>
            <div className="Matchdetail__details__bg">
                <img src="/media/primeprono_terrain.png" alt="terrain" />
            </div>
        </div>
        :null}
        <p>Pronos du match :</p>
        <div className="Matchdetail__tableau">
            {allProno ? 
                <div>
                {allProno.map((prono, index) => (
                    <ComponentMatchDetailProno key={index} data={prono} match={match}/>
                ))}
                </div>
            : "" }
        </div>
      </div>
    );
  }
  
  export default Matchdetail;