import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Link } from 'react-router-dom';

//JS
import { globalUpdateProno, updateBonus } from './js/helpers';

function ComponentAdmin(props) {

    const pronolist = useSelector((state) => state.prono.prono);
    const userlist = useSelector((state) => state.users.users);
    const [matchState, setMatchState] = useState({
        id: null,
        scoreA: null,
        scoreB: null,
        coteA: 1,
        coteN: 1,
        coteB: 1,
        bonus: false,
        statut: "no"
    })
    const [formattedDate, setFormattedDate] = useState('Chargement...')
    //ATTRIBUTION DES BONNES VALEUR DANS LES INPUT EN FONCTION DES PROPS
    useEffect(() => {
        setMatchState({
            id: props.data.id,
            scoreA: props.data.scoreA,
            scoreB: props.data.scoreB,
            coteA: props.data.coteA,
            coteN: props.data.coteN,
            coteB: props.data.coteB,
            bonus: props.data.is_bonus,
            statut: props.data.is_play
        })
    }, [props.data]);

    useEffect(() => {
        setFormattedDate(transformerDate(props.data.date))
    },[matchState])

    function transformerDate(inputDate) {
        const parts = inputDate.split('-');
        const annee = parts[0];
        const mois = parts[1];
        const jour = parts[2];
        const nouvelleDate = `${jour}/${mois}/${annee}`;
        return nouvelleDate;
      }

    const handleChangeMatch = async (e) => {
        const { name, value } = e.target;
        const updatedMatchState = { ...matchState, [name]: value };
        console.log(updatedMatchState);
        setMatchState(updatedMatchState);

        if (updatedMatchState.scoreA !== null && updatedMatchState.scoreB !== null && updatedMatchState.scoreA !== '' && updatedMatchState.scoreB !== '') {
            console.log('Il y a deux valeurs');
            props.toggle(true)
            await globalUpdateProno(updatedMatchState, pronolist, userlist);
            props.toggle(false)
        } else {
            console.log('Il manque une valeur');
        }
    }; 

    const handleChangeBonus = async (e) => {
        console.log(props.data.is_play);
        if(!props.data.is_play){
            const updatedMatchState = { ...matchState, bonus: e.target.checked };
            setMatchState(updatedMatchState);
            props.toggle(true)
            await updateBonus(matchState.id, e.target.checked)
            props.toggle(false)
        }  
    }; 

    const handleFocus = async (e) => {
        const navbar = document.getElementById('js_navabar')
        navbar.classList.add('inactive')
    }; 

    const handleBlur = async (e) => {
        const navbar = document.getElementById('js_navabar')
        navbar.classList.remove('inactive')
    }; 

    return (
        <tr>
            <td>{props.data.equipeA}</td>
            <td>{props.data.equipeB}</td>
            <td>
                {props.data.is_play ? 
                <input type="number" name="scoreA" value={matchState.scoreA} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChangeMatch}/> : 
                <input type="number" disabled className='disabled' name="scoreA" value={matchState.scoreA}/>
                }
            </td>
            <td>
                {props.data.is_play ? 
                    <input type="number" name="scoreB" value={matchState.scoreB} onFocus={handleFocus} onBlur={handleBlur} onChange={handleChangeMatch}/> : 
                    <input type="number" disabled className='disabled' name="scoreB" value={matchState.scoreB}/>
                }
            </td>
            <td className="optional">{props.data.coteA}</td>
            <td className="optional">{props.data.coteN}</td>
            <td className="optional">{props.data.coteB}</td>
            <td className="optional">{formattedDate}</td>
            <td className="optional">{props.data.heure}</td>
            <td><Link to={"/matchs/"+props.data.id}>Pronos</Link></td>
            <td>
                {!props.data.is_play ? <input type="checkbox" name="is_bonus" checked={matchState.bonus} onChange={handleChangeBonus}/> : <input type="checkbox" name="is_bonus" disabled checked={matchState.bonus}/>}
            </td>
        </tr>
    );
}

export default ComponentAdmin;