import React from 'react'
import "../../style/Style__Reglages.scss"

const Component__Modal = (props) => {
  return (
    <div className='Reglage__modal'>
        <div className='Reglage__modal__bloc'>
            <p>Souhaites-tu vraiment de déconnecter ?</p>
            <div className='Reglage__modal__bloc__action'>
                <button onClick={props.closeFunction}>Annuler</button>
                <button className='deco' onClick={props.confirmFunction}>Confirmer</button>
            </div>
        </div>
    </div>
  )
}

export default Component__Modal
