import React, { useEffect, useRef, useState } from 'react'
import "../../style/Style__Reglages.scss"
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../reducers/userSlice';
import bcrypt from "bcryptjs-react";
import { supabase } from "../../js/http-common";
import Compressor from 'compressorjs';

const UpdateProfil = () => {
    const hiddenFileInput = useRef(null);

    const dispatch = useDispatch()
    const userstore = useSelector((state) => state.user.user);

    const [fileMessage, setFileMessage] = useState('Modifier ma photo de profil');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [validMessage, setValidMessage] = useState(null);
    const [validForm, setValidForm] = useState(false);

    const [buttonMessage, setButtonMessage] = useState('Sauvegarder les changements');
    const [formData, setFormData] = useState({
        username: "",
        oldPassword :"",
        password: "",
        confirmPassword: "",
        filLocation: "",
    });

    useEffect(()=>{
        setFormData({username: userstore.username})
    },[userstore])

    const handleFocus = async (e) => {
        const navbar = document.getElementById('js_navabar')
        navbar.classList.add('inactive')
    }; 

    const handleBlur = async (e) => {
        const navbar = document.getElementById('js_navabar')
        navbar.classList.remove('inactive')
    }; 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage(null)
        setButtonMessage('Chargement ...')
        setValidMessage(null)
        setValidForm(false)

        if(formData.password){
            if(formData.password === formData.confirmPassword){
                
                if(!bcrypt.compareSync(formData.oldPassword, userstore.password)){
                    setFormData({
                        oldPassword :"",
                        password: "",
                        confirmPassword: "",
                    })
                    setErrorMessage('Le mot de passe actuel est incorrect, réessayer.')
                    setButtonMessage('Sauvegarder les changements')
                    return;
                }
                const salt = bcrypt.genSaltSync(10);
                const hashedPassword = bcrypt.hashSync(formData.password, salt);
                
                let fileUrl = userstore.avatar
                if(formData.filLocation){fileUrl = "https://ktwovnnuasgfxcvukqqx.supabase.co/storage/v1/object/public/avataruser/"+formData.filLocation}

                const { data, error } = await supabase.from('classement').update(
                    { 
                        username: formData.username,
                        password : hashedPassword,
                        avatar: fileUrl
                    })
                .eq('id', userstore.id)
                .select()

                if(data){
                    console.log(data);
                    dispatch(setUser(data[0]))
                    setButtonMessage('Sauvegarder les changements')
                    setValidForm(true)
                    setValidMessage('Les changement ont été enregistrés avec succés')
                }
                else{
                    console.log(error);
                    setButtonMessage('Sauvegarder les changements')
                    setErrorMessage('Une erreur est survenue')
                }
            }
            else{
                setFormData({
                    oldPassword :"",
                    password: "",
                    confirmPassword: "",
                })
                setErrorMessage('Les mots de passe ne correspondent pas, réessayer.')
                setButtonMessage('Sauvegarder les changements')
            }
        }
        else{
            let fileUrl = userstore.avatar
            if(formData.filLocation){fileUrl = "https://ktwovnnuasgfxcvukqqx.supabase.co/storage/v1/object/public/avataruser/"+formData.filLocation}

            const { data, error } = await supabase.from('classement').update(
                { 
                    username: formData.username,
                    avatar: fileUrl
                })
            .eq('id', userstore.id)
            .select()

            if(data){
                console.log(data);
                dispatch(setUser(data[0]))
                setButtonMessage('Sauvegarder les changements')
                setValidForm(true)
                setValidMessage('Les changement ont été enregistrés avec succés')
            }
            else{
                console.log(error);
                setButtonMessage('Sauvegarder les changements')
                setErrorMessage('Une erreur est survenue')
            }
        }
    };

    const handleUpdate = async (e) => {
        setIsLoading(true)
        const file = e.target.files[0];
        let compressedFile;
        const maxSizeInBytes = 1 * 1024 * 1024;
        console.log(file.size);
        console.log(file);

        new Compressor(file, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                  
              compressedFile = compressedResult
            },
          });

        


        setFileMessage("Vérification en cours...")
        if (!file) {return;}
        if (!["image/jpeg", "image/png"].includes(file.type)) {
            console.error("Le format de fichier n'est pas pris en charge. Veuillez choisir une image JPEG ou PNG.");
            setFileMessage("Le format de ce fichier n'est pas pris en charge.")
            setIsLoading(false)
            return;
        }
        if (file.size > maxSizeInBytes) {
            console.error("La taille du fichier est trop grande. Veuillez choisir un fichier de moins de 1,5 Mo.");
            setFileMessage("Image trop volumineuse.")
            setIsLoading(false)
            return;
        }
        try {
            setFileMessage("Upload en cours...")
            const { data, error } = await supabase.storage
                .from("avataruser")
                .upload(`avatars/${file.name}`, file, { cacheControl: "3600"});
        
            if (error) {
                console.error("Error uploading file:", error.message);
                setFileMessage("Une erreur est survenue.")
                setIsLoading(false)
            } else {
                console.log("File uploaded:", data);
                setFormData({ ...formData, filLocation: `avatars/${file.name}` });
                setFileMessage(`${file.name}`)
                setIsLoading(false)
                
            }
        } catch (error) {
          console.error("Error uploading file:", error.message);
          setFileMessage("Une erreur est survenue.")
          setIsLoading(false)
        }
    };

    const HandleClickFile = (event) => {
        hiddenFileInput.current.click();
    };

    return (
    <div className='Reglage__updateProfil'>
        <h1 className="MainH1">Modifier mon profil</h1>
        <p className="Maindescription">Tu peux modifier les inforsmations de ton compte à tout moment.</p>
        <form className="Reglage__updateProfil__form Mainform" onSubmit={handleSubmit}>
            <p className='infop'>Générale :</p>
            <fieldset>
                <input required type="texte" name="username" placeholder="Nom d'utilisateur*" value={formData.username} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}/>
            </fieldset>
            <fieldset>
                <div className="inputbutton" onClick={HandleClickFile}>
                    <span>{fileMessage}</span>
                </div>
            </fieldset>

            <p className='infop'>Sécurité :</p>
            <fieldset>
                <input type="password" name="oldPassword" placeholder="Mot de passe actuel" value={formData.oldPassword} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}/>
            </fieldset>
            <fieldset>
                <input type="password" name="password" placeholder="Nouveau mot de passe" value={formData.password} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}/>
            </fieldset>
            <fieldset>
                <input type="password" name="confirmPassword" placeholder="Confirmer nouveau mot de passe" value={formData.confirmPassword} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur}/>
            </fieldset>
            <button type="submit">{buttonMessage}</button>
            {
                validForm ? <span className="Reglage__updateProfil__valid">{validMessage}</span> : <span className="Reglage__updateProfil__error">{errorMessage}</span>
            }
            
            <input ref={hiddenFileInput} style={{display: 'none'}} type="file" name="avatar" placeholder="Choisir une photo de profil" onChange={handleUpdate}/>
        </form>
    </div>
    )
}

export default UpdateProfil
