import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { supabase } from '../../js/http-common';
import CompCote from '../../components/Cote/Component__Cote';

function Component_Match(props) {
    const userconnected = useSelector((state) => state.user.user);
    const pronolist = useSelector((state) => state.prono.prono);

    const [formattedDate, setFormattedDate] = useState('')

    const [verifProno, setVerifProno] = useState('neutre')
    const [drapeau, setDrapeau] = useState({
        A: null,
        B: null
    })

    useEffect(() => {
        if(props.data.is_play){
            HandleDrapeau()
            getProno(props.data.id, userconnected.id);
        }
    },[props.data]);

    async function getProno(idmatch, iduser){
        let pronoFind = pronolist.find(item => item.id_match === idmatch && item.id_user === iduser);
        if(pronoFind){
            if(props.data.scoreA == pronoFind.scoreA && props.data.scoreB == pronoFind.scoreB){
                setVerifProno('green')
            }
            else if((props.data.scoreA > props.data.scoreB && pronoFind.scoreA > pronoFind.scoreB)){
                setVerifProno('orange')
            }
            else if((props.data.scoreB > props.data.scoreA && pronoFind.scoreB > pronoFind.scoreA)){
                setVerifProno('orange')
            }
            else if((props.data.scoreA == props.data.scoreB && pronoFind.scoreA == pronoFind.scoreB)){
                setVerifProno('orange')
            }
        }
    }

    function HandleDrapeau(){
        // let linkDrapeauA = "https://flagcdn.com/"+props.data.drapeauA+".svg"
        // let linkDrapeauB = "https://flagcdn.com/"+props.data.drapeauB+".svg"
        let linkDrapeauA = props.data.drapeauA+".png"
        let linkDrapeauB = props.data.drapeauB+".png"

        setDrapeau({
            A: linkDrapeauA,
            B: linkDrapeauB
        })
    }

    return (
        <div> 
            <div className={`Match__container__item ${props.data.is_live ? "liveActive" : ""} ${verifProno === "orange" ? "orange" : ""} ${verifProno === "green" ? "green" : ""} ${props.data.is_bonus ? "bonusActive" : "" }`}>
                <div className='Match__container__item__left'>
                    <div className='Match__container__item__left__drapeau'>
                        <img alt={props.data.equipeA} src={drapeau.A}/>
                    </div>
                    <span>{props.data.equipeA}</span>
                </div>
                <div className='Match__container__item__center'>
                    <div className='Match__container__item__center__top'>{props.data.heure}</div>
                    <div className='Match__container__item__center__center'>
                        <span className='FMedium'>{props.data.scoreA}</span>
                        <span className='FMedium'>{props.data.scoreB}</span>
                    </div>
                    <CompCote correct={verifProno} data={props.data} score={props.data}/>
                </div>
                <div className='Match__container__item__right'>
                    <div className='Match__container__item__right__drapeau'>
                        <img alt={props.data.equipeA} src={drapeau.B}/>
                    </div>
                    <span>{props.data.equipeB}</span>
                </div>
            </div>  
        </div>
        
    );
}

export default Component_Match;
