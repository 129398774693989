import React, { useEffect, useState } from 'react'

const Component__MatchStat__Result = (props) => {

    const [matchPlace, setMatchPlace] = useState('')
    const [matchStatut, setMatchStatut] = useState('')
    
    useEffect(()=>{
        if(props.data){
            if(props.data.equipeA === props.team){
                setMatchPlace('Do.')
                if(props.data.scoreA > props.data.scoreB){setMatchStatut('win')}
                else if(props.data.scoreA < props.data.scoreB){setMatchStatut('def')}
                else if(props.data.scoreA === props.data.scoreB){setMatchStatut('draw')}
            }
            else{
                setMatchPlace('Ex.')
                if(props.data.scoreA < props.data.scoreB){setMatchStatut('win')}
                else if(props.data.scoreA > props.data.scoreB){setMatchStatut('def')}
                else if(props.data.scoreA === props.data.scoreB){setMatchStatut('draw')}
            }
        }
    }, [props.data])

    return (
        <div className='MatchStats__teamresult__right__result'>
            <span>{matchPlace}</span>
            <div className={`${matchStatut === 'win' ? "win" : ""} ${matchStatut === "def" ? "def" : ""} ${matchStatut === "draw" ? "draw" : ""}`}></div>
        </div>  
    )
}

export default Component__MatchStat__Result
