import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import "../../style/Style__MatchStats.scss"
import { useSelector } from "react-redux/es/hooks/useSelector";

//COMP
import Component__MatchStat from './Component__MatchStat';
import Component__MatchCompare from './Component__MatchCompare';


const MatchStats = () => {
    const params = useParams();
    const matchlist = useSelector((state) => state.match.match);
    const [match, setMatch] = useState(null)
    const [drapeau, setDrapeau] = useState({
        A: null,
        B: null
    })

    useEffect(() => {
        let matchFind = matchlist.find(match => match.id === params.id);
        if(matchFind)setMatch(matchFind)
        let linkDrapeauA = matchFind.drapeauA+".png"
        let linkDrapeauB = matchFind.drapeauB+".png"
        setDrapeau({A: linkDrapeauA,B: linkDrapeauB})
    }, [matchlist]);

    return (
        <div className='MatchStats'>
        <h1 className="MainH1">Détail du matchs</h1>
        {match ? 
            <div className="MatchStats__details">
                <div className="MatchStats__details__main">
                    <div className="MatchStats__details__main__left">
                        <span>{match.equipeA}</span>
                        <div className="MatchStats__details__main__left__drapeau">
                            <img src={drapeau.A} alt="drapeau" />
                        </div>
                        
                    </div>
                    <div className="MatchStats__details__main__center">
                        <div></div>
                        <div></div>
                        <div className="MatchStats__details__main__center__bot">
                            <div className="MatchStats__details__main__center__bot__cote">
                                <span>{match.coteA}</span>
                            </div>
                            <div className="MatchStats__details__main__center__bot__cote">
                                <span>{match.coteN}</span>
                            </div>
                            <div className="MatchStats__details__main__center__bot__cote">
                                <span>{match.coteB}</span>
                            </div>
                        </div>
                    </div>
                    <div className="MatchStats__details__main__right">
                        <span>{match.equipeB}</span>
                        <div className="MatchStats__details__main__left__drapeau">
                            <img src={drapeau.B} alt="drapeau" />
                        </div>
                        
                    </div>
                </div>
                <div className="MatchStats__details__bg">
                    <img src="/media/primeprono_terrain.png" alt="terrain" />
                </div>
            </div>
        :null}
        <p className='infop'>Derniers matchs :</p>
        {match ? 
            <div>
                <Component__MatchStat team={match.equipeA} drap={match.drapeauA+".png"} /> 
                <Component__MatchStat team={match.equipeB} drap={match.drapeauB+".png"} /> 
            </div>
        : <span>Chargement....</span>}
        <br/>
        <p className='infop'>Statistiques :</p>
        {match ? 
            <div>
                <Component__MatchCompare data={matchlist} teamA={match.equipeA} teamB={match.equipeB}/>
            </div>
        : <span>Chargement....</span>}
            
        </div>
    )
}

export default MatchStats
