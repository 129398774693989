import React, {useState} from "react";
import "../../style/Style__Admin.scss"

//COMP
import ComponentAdmin from "./Component__Admin";
import Component__Admin__Loader from "./Component__Admin__Loader";

//STORE
import { useSelector } from 'react-redux'

function Admin(){
    const matchlist = useSelector((state) => state.match.match);
    const [showLoader, setShowLoader] = useState(false)
    const [searchMatch, setSearchMatch] = useState('')

    function toggleLoader(bool){
        setShowLoader(bool)
    }

    const handleChange = (e) => {
        const value = e.target.value;
        setSearchMatch(value);
      };
    return (
      <div className='Admin'>
        {showLoader ? <Component__Admin__Loader /> : null }
        <h1 className="MainH1">Admin</h1>
        <input className="Admin__search" type="text" placeholder="Rechercher un match" value={searchMatch} onChange={handleChange} />
        <table>
            <thead>
                <tr>
                    <th>Équipe A</th>
                    <th>Équipe B</th>
                    <th>Score A</th>
                    <th>Score B</th>
                    <th className="optional">Cote A</th>
                    <th className="optional">Cote Nul</th>
                    <th className="optional">Cote B</th>
                    <th className="optional">Date</th>
                    <th className="optional">Heure</th>
                    <th>Pronos</th>
                    <th>Bonus</th>
                </tr>
            </thead>
            {matchlist ? 
                <tbody>
                    {matchlist
                        .filter(match => match.equipeA.toLowerCase().includes(searchMatch.toLowerCase()) ||
                        match.equipeB.toLowerCase().includes(searchMatch.toLowerCase()))
                        .map(match => (
                            <ComponentAdmin key={match.id} data={match} toggle={toggleLoader} />
                        ))
                    }
                </tbody>
            : <tbody></tbody>}
            
        </table>
      </div>
    );
}
  
export default Admin;