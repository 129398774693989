import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux/es/hooks/useSelector";

function ComponentMatchDetailProno(props) {

    const userconnected = useSelector((state) => state.user.user);
    const [correctProno, setCorrectProno] = useState(false)

    useEffect(() => {
        if(props.data.scoreA == props.match.scoreA && props.data.scoreB == props.match.scoreB){
            setCorrectProno(true)
        }
    }, [props.data]);

  return (
    <div>
      <div className={`Matchdetail__tableau__item`}>
          <div className="Matchdetail__tableau__left">{props.data.username} 
            {correctProno ? <span className='correctspan'> (Exact)</span> : null}
          </div>
          
           <div className="Matchdetail__tableau__center">{props.data.scoreA} - {props.data.scoreB }</div>
      </div>
    </div>
  );
}

export default ComponentMatchDetailProno;
